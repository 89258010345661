import {GenericWalletAdapter} from './GenericWalletAdapter'
import SignClient from '@walletconnect/sign-client'
import {N3WalletConnectConfig} from '@/config/N3WalletConnectConfig'
import {WalletAdapterContext} from '@/store/modules/WalletAdapterModule'
import {WalletMutation} from '@/enums/WalletMutation'
import {WalletPlatform} from '@/enums/WalletPlatform'
import {PopulateSdkStatusParams} from '@/model/wallets/types/WalletTypes'
import {SdkNotInitializedError} from '@/model/wallets/types/WalletErrors'
import {InvocationBuilderResult} from '@/model/wallets/invocationsBuilder/GenericInvocationBuilder'
import {WcSdk} from '@/libs/wallet-connect-sdk-core/WcSdk'
import {
  ContractInvocationMulti,
  NetworkType,
} from '@/libs/wallet-connect-sdk-core/types'

export class NeonN3WalletAdapter implements GenericWalletAdapter {
  private sdk: WcSdk | null = null
  private client: SignClient | null = null
  private namespace = 'neo3'

  isInstalled(): boolean {
    return this.sdk !== null && this.client !== null
  }

  async init(context: WalletAdapterContext) {
    try {
      this.client = await SignClient.init(N3WalletConnectConfig.options)
      this.sdk = new WcSdk(this.client)

      this.sdk.loadSession()

      if (this.sdk.isConnected()) {
        context.commit(
          WalletMutation.N3_POPULATE_CONNECTED_WALLET_TYPE,
          WalletPlatform.NEON
        )
        context.commit(
          WalletMutation.N3_POPULATE_ADDRESS,
          this.sdk.getAccountAddress(this.namespace)
        )
      }

      this.client.on('session_delete', () => {
        context.commit(WalletMutation.N3_POPULATE_CONNECTED_WALLET_TYPE, null)
        context.commit(WalletMutation.N3_POPULATE_ADDRESS, null)
        context.commit(WalletMutation.N3_POPULATE_LAST_WALLET_CONNECTED, null)
      })

      context.commit(WalletMutation.N3_POPULATE_SDK_STATUS, {
        wallet: WalletPlatform.NEON,
        status: 'started',
      } as PopulateSdkStatusParams)
    } catch (error) {
      context.commit(WalletMutation.N3_POPULATE_SDK_STATUS, {
        wallet: WalletPlatform.NEON,
        status: 'error',
      } as PopulateSdkStatusParams)
    }
  }

  async connect(context: WalletAdapterContext) {
    if (!this.sdk) throw SdkNotInitializedError(WalletPlatform.NEON)

    const {approval, uri} = await this.sdk.createConnection(
      N3WalletConnectConfig.network,
      N3WalletConnectConfig.methods,
      this.namespace
    )

    context.commit(WalletMutation.N3_POPULATE_URI, uri ?? '')

    await approval()

    this.sdk.loadSession()

    context.commit(
      WalletMutation.N3_POPULATE_CONNECTED_WALLET_TYPE,
      WalletPlatform.NEON
    )

    context.commit(
      WalletMutation.N3_POPULATE_ADDRESS,
      this.sdk.getAccountAddress(this.namespace)
    )
  }

  async disconnect() {
    if (!this.sdk) throw SdkNotInitializedError(WalletPlatform.NEON)
    await this.sdk.disconnect()
  }

  async invoke(multiInvoke: InvocationBuilderResult) {
    if (!this.sdk) throw SdkNotInitializedError(WalletPlatform.NEON)

    return await this.sdk.sendRequest(
      'invokeFunction',
      this.namespace,
      multiInvoke
    )
  }
}
