import {rpc, wallet} from '@cityofzion/neon-core'
import {EnvHelper} from '@/helpers/EnvHelper'
import axios from 'axios'
import {$} from '@/facade'
import {NeonInvoker} from '@cityofzion/neon-invoker'

export class NeoHelper {
  static neonInvoker: NeonInvoker | null = null
  static readonly rpcUrl = EnvHelper.VUE_APP_RPC_URL
  static readonly attemptsToValidateTransaction =
    EnvHelper.VUE_APP_MAX_ATTEMPTS_TO_VALIDATE_TRANSACTION ?? 25

  static async getNeonInvoker(): Promise<NeonInvoker> {
    if (!this.neonInvoker) {
      this.neonInvoker = await NeonInvoker.init({
        rpcAddress: this.rpcUrl,
        account: new wallet.Account(),
      })
    }

    return this.neonInvoker
  }

  static async validateTransaction(transactionHash: string): Promise<boolean> {
    const rpcClient = new rpc.RPCClient(this.rpcUrl)
    const maxTries = this.attemptsToValidateTransaction

    let tries = 0

    do {
      tries += 1

      await new Promise(resolve => setTimeout(resolve, 4000))

      try {
        const result = await rpcClient.getApplicationLog(transactionHash)

        const execution = result.executions[0]

        if (!execution || !execution.stack || execution.vmstate !== 'HALT')
          break

        const [stack] = execution.stack

        if (!stack || !stack.value) break

        if (execution.notifications.length <= 0) break

        return true
        // eslint-disable-next-line no-empty
      } catch {}
    } while (tries < maxTries)

    return false
  }

  static async getVoterData(address: string) {
    const {data} = await axios.post(this.rpcUrl, {
      jsonrpc: '2.0',
      id: 1,
      method: 'invokefunction',
      params: [
        '0xef4073a0f2b305a38ec4050e4d3d28bc40ea63f5',
        'getAccountState',
        [
          {
            type: 'Hash160',
            value: new wallet.Account(address).scriptHash,
          },
        ],
      ],
    })

    const candidateNeverVoted = data.result.stack[0].type === 'Any'
    if (candidateNeverVoted) {
      return ''
    }

    const voterData = data.result.stack[0].value[2]
    if (voterData.type === 'Any') {
      return ''
    }

    const candidatePubKey = voterData.value

    const candidatePubKeyHex = Buffer.from(candidatePubKey, 'base64').toString(
      'hex'
    )

    return new wallet.Account(candidatePubKeyHex).publicKey
  }

  static async getUnclaimedGasData(address: string) {
    const {data} = await axios.post(this.rpcUrl, {
      jsonrpc: '2.0',
      method: 'getunclaimedgas',
      params: [address],
      id: 1,
    })

    return data.result
  }

  static async calculateUnclaimedGasFee(address: string) {
    const neonInvoker = await this.getNeonInvoker()

    const scriptHash = NeoHelper.getScriptHashFromAddress(address)

    return neonInvoker.calculateFee({
      invocations: [
        {
          operation: 'transfer',
          scriptHash: EnvHelper.VUE_APP_NEO_SCRIPT_HASH,
          args: [
            {
              type: 'Hash160',
              value: scriptHash,
            },
            {
              type: 'Hash160',
              value: scriptHash,
            },
            {
              type: 'Integer',
              value: 0,
            },
            {
              type: 'Any',
              value: null,
            },
          ],
        },
      ],
    })
  }

  static getScriptHashFromAddress(address: string) {
    return `0x${new wallet.Account(address).scriptHash}`
  }

  static validateN3Address(address: string) {
    if (!address) {
      $.toast.abort('Empty N3 address')
      return
    }

    return address.length === 34 && address.startsWith('N')
  }
}
