import {RootState, WatchWalletState} from '@/types/store'
import {AccessorHandler} from '@simpli/vuex-typescript'
import {WalletMutation} from '@/enums/WalletMutation'
import {ActionContext, Module} from 'vuex'

export type WatchWalletContext = ActionContext<WatchWalletState, RootState>

@AccessorHandler
export class WatchWalletModule implements Module<WatchWalletState, RootState> {
  namespaced = true

  state: WatchWalletState = {
    address: null,
  }

  getters = {
    address: (state: WatchWalletState) => state.address,
  }

  actions = {
    setAddress(context: WatchWalletContext, address: string) {
      context.commit('SET_ADDRESS', address)
    },
  }

  mutations = {
    SET_ADDRESS(state: WatchWalletState, address: string) {
      state.address = address
    },
  }
}
