import {RootState, WalletAdapterState} from '@/types/store'
import {walletAdapter, store} from '@/store'
import {AccessorWrapper} from '@simpli/vuex-typescript'
import {WalletPlatform} from '@/enums/WalletPlatform'
import {InvokeParams} from '@/model/wallets/types/WalletTypes'

export const wrapper = new AccessorWrapper<WalletAdapterState, RootState>(
  'walletAdapter'
)

export abstract class WalletAdapter {
  static $accessors = wrapper.accessors

  // Getters

  // NEO3
  static get n3Address() {
    return WalletAdapter.$accessors.read(walletAdapter.getters.n3Address)(store)
  }

  static get n3Uri() {
    return WalletAdapter.$accessors.read(walletAdapter.getters.n3Uri)(store)
  }

  static get n3ConnectedWalletPlatform() {
    return WalletAdapter.$accessors.read(
      walletAdapter.getters.n3ConnectedWalletPlatform
    )(store)
  }

  // NEO X
  static get xAddress() {
    return WalletAdapter.$accessors.read(walletAdapter.getters.xAddress)(store)
  }

  static get xUri() {
    return WalletAdapter.$accessors.read(walletAdapter.getters.xUri)(store)
  }

  static get xConnectedWalletPlatform() {
    return WalletAdapter.$accessors.read(
      walletAdapter.getters.xConnectedWalletPlatform
    )(store)
  }

  // Actions

  // NEO3
  static initN3Wallet() {
    return WalletAdapter.$accessors.dispatch(
      walletAdapter.actions.initN3Wallet
    )(store)
  }

  static connectN3Wallet(walletPlatform: WalletPlatform) {
    return WalletAdapter.$accessors.dispatch(
      walletAdapter.actions.connectN3Wallet
    )(store, walletPlatform)
  }

  static disconnectN3Wallet() {
    return WalletAdapter.$accessors.dispatch(
      walletAdapter.actions.disconnectN3Wallet
    )(store)
  }

  static canUseN3Wallet(walletPlatform: WalletPlatform) {
    return WalletAdapter.$accessors.dispatch(
      walletAdapter.actions.canUseN3Wallet
    )(store, walletPlatform)
  }

  static invokeN3Wallet(invokeParams: InvokeParams) {
    return WalletAdapter.$accessors.dispatch(
      walletAdapter.actions.invokeN3Wallet
    )(store, invokeParams)
  }

  static async executeWithConnectedN3Wallet(
    actionToDoAfterConnect: () => Promise<void> | void
  ) {
    return WalletAdapter.$accessors.dispatch(
      walletAdapter.actions.executeWithConnectedN3Wallet
    )(store, actionToDoAfterConnect)
  }

  // NEO X
  static initXWallet() {
    return WalletAdapter.$accessors.dispatch(
      walletAdapter.actions.initNeoXWallet
    )(store)
  }

  static connectXWallet(walletPlatform: WalletPlatform) {
    return WalletAdapter.$accessors.dispatch(
      walletAdapter.actions.connectNeoXWallet
    )(store, walletPlatform)
  }

  static disconnectXWallet() {
    return WalletAdapter.$accessors.dispatch(
      walletAdapter.actions.disconnectNeoXWallet
    )(store)
  }

  static canUseXWallet(walletPlatform: WalletPlatform) {
    return WalletAdapter.$accessors.dispatch(
      walletAdapter.actions.canUseNeoXWallet
    )(store, walletPlatform)
  }

  static invokeXWallet(invokeParams: InvokeParams) {
    return WalletAdapter.$accessors.dispatch(
      walletAdapter.actions.invokeNeoXWallet
    )(store, invokeParams)
  }

  static async executeWithConnectedXWallet(
    actionToDoAfterConnect: () => Promise<void> | void
  ) {
    return WalletAdapter.$accessors.dispatch(
      walletAdapter.actions.executeWithConnectedXWallet
    )(store, actionToDoAfterConnect)
  }
}
