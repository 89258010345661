export enum WalletMutation {
  N3_POPULATE_CONNECTED_WALLET_TYPE = 'N3_POPULATE_CONNECTED_WALLET_TYPE',
  N3_POPULATE_SDK_STATUS = 'N3_POPULATE_SDK_STATUS',
  N3_POPULATE_URI = 'N3_POPULATE_URI',
  N3_POPULATE_ADDRESS = 'N3_POPULATE_ADDRESS',
  N3_POPULATE_LAST_WALLET_CONNECTED = 'N3_POPULATE_LAST_WALLET_CONNECTED',
  X_POPULATE_CONNECTED_WALLET_TYPE = 'X_POPULATE_CONNECTED_WALLET_TYPE',
  X_POPULATE_SDK_STATUS = 'X_POPULATE_SDK_STATUS',
  X_POPULATE_URI = 'X_POPULATE_URI',
  X_POPULATE_ADDRESS = 'X_POPULATE_ADDRESS',
  X_POPULATE_LAST_WALLET_CONNECTED = 'X_POPULATE_LAST_WALLET_CONNECTED',
}
