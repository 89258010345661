import {EnvHelper} from '@/helpers/EnvHelper'
import {Method, NetworkType} from '@/libs/wallet-connect-sdk-core/types'
import {SignClientTypes} from '@walletconnect/types'

export class N3WalletConnectConfig {
  static readonly methods: Method[] = ['invokeFunction']

  static readonly network: NetworkType = 'neo3:mainnet'

  static readonly options: SignClientTypes.Options = {
    projectId: '8b536aaae30c4232e8f4cf3f4617499d',
    relayUrl: 'wss://relay.walletconnect.com',
    metadata: {
      name: 'nDapp',
      description:
        'Your source of information and data for applications and development groups within the Neo blockchain ecosystem.',
      url: 'https://ndapp.org/',
      icons: ['https://ndapp.org/dist/img/ndapp-loader.png'],
    },
  }
}
