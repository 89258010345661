import {AccessorWrapper} from '@simpli/vuex-typescript'
import {watchWallet, store} from '@/store'
import {RootState, WatchWalletState} from '@/types/store'

export const wrapper = new AccessorWrapper<WatchWalletState, RootState>(
  'watchWallet'
)

export abstract class WatchWallet {
  static $accessors = wrapper.accessors

  // Getters
  static get address() {
    return WatchWallet.$accessors.read(watchWallet.getters.address)(store)
  }

  // Actions
  static setAddress(address: string) {
    return WatchWallet.$accessors.dispatch(watchWallet.actions.setAddress)(
      store,
      address
    )
  }
}
