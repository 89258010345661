import Vue from 'vue'
import Vuex, {Store} from 'vuex'
import {RootStore} from '@/store/RootStore'
import {AuthModule} from '@/store/modules/AuthModule'
import {WalletAdapterModule} from '@/store/modules/WalletAdapterModule'
import {WatchWalletModule} from '@/store/modules/WatchWalletModule'

Vue.use(Vuex)

export const root = new RootStore()
export const auth = new AuthModule()
export const walletAdapter = new WalletAdapterModule()
export const watchWallet = new WatchWalletModule()

root.modules = {
  auth,
  walletAdapter,
  watchWallet,
}

export const store = new Store(root)
